.create_todo {
  position: fixed;
  bottom: 5rem;
  right: 0;
  width: 100%;
  z-index: 10;
}

.create_todo__button {
  margin: 0 auto;
  font-size: 1.6rem;
  background-color: var(--color-success);
  -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.59);
}

.create_todo__button:hover {
  background-color: var(--color-success-hover);
  font-size: 1.7rem;
}

.create_todo__button:active {
  background-color: var(--color-success-active);
}

.create_todo__button_close {
  background-color: var(--color-danger);
}
.create_todo__button_close:hover {
  background-color: var(--color-danger-hover);
}

.create_todo__button_close:active {
  background-color: var(--color-danger-active);
}
