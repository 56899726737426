.todo_item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 1.4rem;

  width: 80%;
  height: 5rem;
  margin: 0 auto 2rem;
  background-color: var(--color-info);
  border-radius: var(--border-radius-items);

  -webkit-box-shadow: 0px 0px 25px -15px rgba(0,0,0,0.59);
  -moz-box-shadow: 0px 0px 25px -15px rgba(0,0,0,0.59);
  box-shadow: 0px 0px 25px -15px rgba(0,0,0,0.59);
}

.todo_item_completed {
  color: rgba(0, 0, 0, 0.5);
  background-color: var(--color-info-transparent);
}
.todo_item__text{
  margin: 0;
}
.todo_item_completed .todo_item__text {
  text-decoration: line-through;
}

.todo_item:last-child {
  margin-bottom: 0;
}

.todo_item__icon{
  padding: 0 .5rem;
  border-radius: var(--border-radius-items) 0 0 var(--border-radius-items);
}
.todo_item__delete{
  border-radius: 0 var(--border-radius-items) var(--border-radius-items) 0;
}

.todo_item__icon_button{
  font-family: "JetBrains Mono";
  font-size: 1.6rem !important;
  width: 6rem;
  min-height: 100%;
}

.todo_item__delete,
.todo_item__icon {
  display: block;
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  height: 100%;
  transition: all .1s ease-in;
}

.todo_item__delete_button,
.todo_item__icon_button{
  font-size: 1.8rem;
  background-color: transparent;
  border: 0;
  height: 100%;
}

.todo_item__delete:hover{
  background-color: var(--color-danger);
}

.todo_item__icon:hover{
  background-color: var(--color-success);
}
.todo_item_completed .todo_item__icon:hover{
  background-color: var(--color-info);
}

.todo_item__delete:hover,
.todo_item__icon:hover,
.todo_item__delete_button:hover,
.todo_item__icon_button:hover{
  cursor: pointer;
}
