@import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;600;700&family=VT323&display=swap");

html {
  font-size: 62.5%;
} /* =10px */

:root {
  --background-color: #e6ffff;
  --color-success: rgb(169, 250, 192);
  --color-success-hover: rgb(154, 235, 177);
  --color-success-active: rgb(139, 218, 161);
  --color-danger: rgb(255, 138, 128);
  --color-danger-hover: rgb(255, 119, 107);
  --color-danger-active: rgb(253, 97, 83);
  --color-info: rgb(142, 219, 252);
  --color-info-transparent: rgba(142, 219, 252, 0.5);
  --border-radius-items: 0.5rem;
  --border-radius-buttons: 1rem;
}

body {
  font-family: "JetBrains Mono", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

#root {
  position: relative;
  min-height: 100vh;
  max-width: 50rem;
  margin: 0 auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text-info {
  text-align: center;
  font-size: 1.8rem;
}

.button {
  font-family: "JetBrains Mono", monospace;
  display: block;
  padding: 1.5rem 2rem;
  border-radius: var(--border-radius-buttons);
  border: 0;
  transition: all 0.15s ease-in-out;
}

.small_button {
  padding: 1rem 1.5rem;
}

.mini_button {
  display: inline-block;
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-success);
}

.mini_button:hover {
  cursor: default !important;
}

.button:hover {
  cursor: pointer;
}

.content_loader{
  text-align: center;
}