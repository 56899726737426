.modal {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: -1rem;
  left: -1rem;
  right: -1rem;
  bottom: -1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
