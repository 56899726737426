.todo_search {
  display: block;
  font-size: 1.8rem;
  font-family: "JetBrains Mono";
  text-align: center;
  width: 50%;
  height: 3.5rem;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 0.4rem;
}
